<template>
  <div>
    <!-- Filter -->
    <div class="row justify-content-end mt-n3">
      <div class="col-md-4">
        <form @submit.stop.prevent="search">
        <b-input-group>
          <template #append>
            <b-button variant="success" squared size="sm" @click="search">
              Cari
            </b-button>
            <b-button variant="danger" squared size="sm" @click="reset">
              Reset
            </b-button>
          </template>
          <b-form-input
            type="text"
            v-model="filter.name"
            placeholder="Cari Nama"
          ></b-form-input>
        </b-input-group>
        </form>
      </div>
    </div>
    <b-table
      striped
      hover
      :items="items"
      :fields="fields"
      class="mt-3"
      responsive
    >
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.key === 'action' ? '10%' : '' }"
        />
      </template>

      <template #cell(action)="data">
        <template v-if="purpose == 'modal-subject'">
          <b-button
            size="sm"
            class=""
            variant="primary"
            @click="
              chooseData({
                id: data.item.id,
                name: data.item.name,
                sks: data.item.sks,
                code: data.item.code,
              })
            "
          >
            Pilih
          </b-button>
        </template>
      </template>
    </b-table>

    <b-pagination
      v-if="items.length != 0"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-click="pageOnClick"
      class="mt-4"
    ></b-pagination>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";

export default {
  props: {
    purpose: String,
  },
  data() {
    return {
      // filter
      filter: {
        name: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "code",
          label: "Kode",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "sks",
          label: "SKS",
          sortable: true,
        },
        {
          key: "description",
          label: "Keterangan",
          sortable: true,
        },
        {
          key: "subject_group_name",
          label: "Kelompok",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          tdClass: "nowrap",
        },
      ],
      items: [],
      // access
      btnAccess: true,
    };
  },
  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      //("evt", evt);
      //("page", page);
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}`;
      let response = await module.paginate(
        "api/subjects",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

    search() {
      this.pagination();
    },
    reset() {
      this.filter.name = "";
      this.pagination();
    },

    chooseData(value) {
      this.$emit("chooseData", value);
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("api/subjects/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "9016") {
          this.btnAccess = true;
        }
      }
    },
  },
  mounted() {
    this.pagination();
    this.checkAccess();
  },
};
</script>

<style>
.nowrap {
  white-space: nowrap;
}
</style>
