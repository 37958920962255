<template>
  <div>
    <b-form-group id="input-group-student_name">
      <label for="input-student_name">Nama Mahasiswa:</label>
      <b-form-input
        id="input-student_name"
        v-model="form.student_name"
        placeholder="Nama Mahasiswa"
        readonly
      ></b-form-input>
      <small class="text-danger">{{ error.student_name }}</small>
    </b-form-group>

    <b-form-group id="input-group-registration_number">
      <label for="input-registration_number">NIM:</label>
      <b-form-input
        id="input-registration_number"
        v-model="form.registration_number"
        placeholder="NIM"
        readonly
      ></b-form-input>
      <small class="text-danger">{{ error.registration_number }}</small>
    </b-form-group>

    <b-form-group
      id="input-group-semester"
      label="Semester:"
      label-for="input-semester"
    >
      <treeselect
        v-model="form.semester_id"
        :multiple="false"
        placeholder="Pilih Semester"
        :options="semesters"
      />
      <small class="text-danger">{{ error.semester_id }}</small>
    </b-form-group>

    <b-form-group
      id="input-group-study_program_id"
      label="Program Studi:"
      label-for="input-study_program_id"
    >
      <treeselect
        v-model="form.study_program_id"
        :multiple="false"
        placeholder="Pilih Program Studi"
        :options="study_programs"
        disbled
      />
      <small class="text-danger">{{ error.study_program_id }}</small>
    </b-form-group>

    <b-form-group
      id="input-group-faculty_id"
      label="Fakultas:"
      label-for="input-faculty_id"
    >
      <treeselect
        v-model="form.faculty_id"
        :multiple="false"
        placeholder="Pilih Fakultas"
        :options="faculties"
        disabled
      />
      <small class="text-danger">{{ error.faculty_id }}</small>
    </b-form-group>

    <!-- <b-form-group id="input-group-phone">
      <label for="input-phone">No. Telp / WA:</label>
      <b-form-input
        id="input-phone"
        v-model="form.phone"
        placeholder="No. Telp / WA"
        readonly
      ></b-form-input>
      <small class="text-danger">{{ error.phone }}</small>
    </b-form-group>

    <b-form-group id="input-group-email">
      <label for="input-email">Alamat Email:</label>
      <b-form-input
        id="input-email"
        v-model="form.email"
        placeholder="Alamat Email"
        readonly
      ></b-form-input>
      <small class="text-danger">{{ error.email }}</small>
    </b-form-group> -->

    <b-form-group id="input-group-address">
      <label for="input-address">Alamat:</label>
      <b-form-textarea
        id="input-address"
        v-model="form.address"
        placeholder="Alamat"
        rows="4"
        max-rows="8"
      ></b-form-textarea>
      <small class="text-danger">{{ error.address }}</small>
    </b-form-group>

    <slot name="main"></slot>

    <b-form-group id="input-group-image" v-if="!hideUploadParentalConsent">
      <label for="input-image"
        >Surat Izin Orang Tua: </label
      >
      <b-form-file
        v-model="form.parental_consent_file"
        placeholder="Upload Surat Izin Orang Tua"
        drop-placeholder="Lepaskan file di sini..."
        accept=".pdf"
        @change="onFileUpload"
        ref="parental"
      ></b-form-file>
      <small class="text-danger">{{ error.parental_consent_file }}</small>
    </b-form-group>
  </div>
  <!--end: Wizard Step 1-->
</template>

<script>
import module from "@/core/modules/CrudModule.js";

export default {
  props: {
    form: Object,
    route: String,
    title: String,
    purpose: String,
    currentPhoto: String,
    hideUploadParentalConsent:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formData: new FormData(),
      error: {
        semester_id: "",
        parental_consent_file: "",
      },
      current_photo: "/img/default/blank.jpg",
      default_photo: "/img/default/blank.jpg",
      semesters: [],
      study_programs: [],
      faculties: [],
    };
  },
  methods: {
    async getSemesterActive() {
      let response = await module.get("api/semesters/now");
      // If Data Not Found
      if (response != null) {
        this.form.semester_id = response.id;
      }
    },
    async getSemesterOption() {
      let response = await module.setTreeSelect("api/semester-groups");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.semesters = response.data;
        this.semesters.unshift({
          label: "Pilih Semester",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getMajorOption() {
      let response = await module.setTreeSelect("api/majors");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.study_programs = response.data;
        this.study_programs.unshift({
          label: "Pilih Program Studi",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getFacultyOption() {
      let response = await module.setTreeSelect("api/faculties");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.faculties = response.data;
        this.faculties.unshift({
          label: "Pilih Fakultas",
          id: "",
          isDisabled: true,
        });
      }
    },
    onFileUpload(evt) {
      this.form.parental_consent_file = evt.target.files[0];
      console.log("parental_consent_file", this.form.parental_consent_file);
      console.log("refs", this.$refs);
    },
    onRemove() {
      this.current_photo = null;
      this.form.photo = "";
    },
    validationCheck() {
      let status = true;
      if (this.purpose == "add") {
        if (
          this.form.hasOwnProperty("parental_consent_file") == false ||
          this.form.parental_consent_file == ""
        ) {
          this.error.parental_consent_file = "Surat izin orang tua belum dipilih";
          status = false;
        } else {
          this.error.parental_consent_file = "";
        }
      }

      return status;
    },
  },
  mounted() {
    this.getSemesterOption();
    this.getMajorOption();
    this.getFacultyOption();
    if (this.purpose != "edit") {
      this.getSemesterActive();
    }

    this.$root.$on("validationImageFalse", () => {
      this.error.parental_consent_file = "";
    });
    this.$root.$on("validationImageTrue", () => {
      this.error.parental_consent_file = "Surat izin orang tua belum dipilih";
    });
  },
  computed: {
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    },
  },
  watch: {
    form: function (newVal, oldVal) {
      if (this.purpose == "edit") {
        // this.form = this.form;
        this.form.description = this.form.description;
        this.form.semester_id = this.form.semester_id;
        // this.current_photo = this.form.sign;
        // this.default_photo = this.form.sign;
        let fileName = this.form.parental_consent.split("_____")
        let fileConf = {
            name: fileName[fileName.length - 1],
            type: "application/pdf",
            size: 148870
        }
        this.$refs.parental.files.push(fileConf)
      }
    },
  },
};
</script>

<style scoped>
.image-input-wrapper {
  width: 180px !important;
  height: 180px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>