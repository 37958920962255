<template>
  <div>
    <b-modal
      id="modal-subject"
      size="xl"
      title="Daftar Mata Kuliah"
      hide-footer
    >
      <Table purpose="modal-subject" @chooseData="chooseData" />
    </b-modal>
  </div>
</template>

<script>
import Table from "@/view/components/masters/subjects/Table.vue";

export default {
  props: {},

  components: {
    Table,
  },

  methods: {
    chooseData(value) {
      this.$emit("chooseData", value);
    },
  },
};
</script>

<style>
</style>
